.Product {
  width: 100%;
  margin-bottom: 5px;

  img {
    width: 100%;
  }

  .ProductInfo {
    width: 100%;
    padding: 5px;

    a {
      display: block;
      height: 20px;
      margin-bottom: 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.Product_options {
  width: 100%;
  height: 100%;
  background-color: rgba(1, 1, 1, 0.3);
  position: absolute;
  top: 0;
  opacity: 0;
  transition: .3s ease all;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.Product_options_deleting {
  width: 100%;
  height: 100%;
  background-color: rgba(1, 1, 1, 0.3);
  position: absolute;
  top: 0;
  opacity: 1;
  transition: .3s ease all;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.Product_options:hover {
  opacity: 1;
}

.description {
  clear: both;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #757575;

  &.empty-description {
    color: red;
  }
}

.p-details {
    clear: both;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    // color: #757575;
}

.p-details-empty {
  clear: both;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #bebebe;
}

@media screen and (max-width: 761px) {
  .Product_options {
    opacity: 1;
  }

  .Product {
    width: auto !important;
  }
}

.fade-inn {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

/* Add this CSS class to hide the content initially */
.hiddenn {
  opacity: 0;
}

.buttonBigWhenHover {
  transform: scale(1);
  transition: transform 0.1s ease-in-out;
  /* Add a smooth transition for the transform property */
}

.buttonBigWhenHover:hover {
  transform: scale(1.15);
}

.buttonSimpleGreyHover {
  // transform: scale(1);
  padding-left: 5px !important;
  padding-right: 5px !important;
  // transition: transform 0.05s ease-in-out;
  /* Add a smooth transition for the transform property */
}

.buttonSimpleGreyHover:hover {
  // transform: scale(1.10);
  background-color: #e9e9e9;
  border-radius:  0.25rem 0.25rem 0 0;
}

.snackbar {
  position: relative;
  background-color: #fff;
  color: #333;
  border-radius: 12px;
  border: none;
  opacity: 0;
  height: 0;
  transition: all 0.3s ease-in-out;
  //  width: 400px;
  // white-space: nowrap;
  // text-overflow: ellipsis;
}

.snackbar.visible {
  height: auto;
  margin-top: 14px;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
  padding: 10px;
  opacity: 1;
  // width: 400px;
}

#portal-root {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items:end;
  bottom: 40px;
  right: 20px;
  padding-left: 20px;
}

.unselectable {
  user-select: none;
  pointer-events: none;
}

.link-style {
  color:black
}
.link-style:hover {
  color:rgb(37, 112, 211);
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2)
}

.link-style:hover .pl-link-img {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2)
}

.elipsis {
  max-width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
}

.text-ellipsis-responsive {
  position: relative;
  width: 100%;
  height: 42px;
  // clear: both;
  overflow: hidden;
  overflow-x: auto;
  // white-space: nowrap;
  // text-overflow: ellipsis !important;
}

.title-container {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.opacc:hover {
  opacity: 0.7;
  cursor: pointer !important;
}

.ctooltip-container {
  position: relative;
  display: inline-block;
}

.ctooltip {
  position: absolute;
  background-color: black;
  color: white;
  padding: 2px;
  border-radius: 5px;
  z-index: 1;
  top: 100%; /* Position below the container */
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  opacity: 0.9;
}

.ctooltip::after {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  border-width: 3px;
  border-style: solid;
  border-color: black transparent transparent transparent; /* Change border-color */
}

@keyframes fadeInOut {
  0% {
    opacity: 0.2;
  }
  // 50% {
  //   opacity: 1;
  // }
  100% {
    opacity: 1;
  }
}

.fade-in-out {
  animation: fadeInOut 1.5s infinite alternate;
}
