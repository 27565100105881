$white: white;
$yellow: #ffb000;
$whiteGray: #eee;
$dark: #343a40;

$bg1: #ffb300;
$bg1: #ffc233;
$bg1: #ffce5c;
$bg1: #ffd87d;
$bg1: #ffe097;
$bg1: #ffe6ac;
$bg1: #ffebbd;
$bg1: #ffefca;
$bg1: #fff2d5;
$bg1: #fff5dd;

.no-products {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 50vh;
  height: 50vh;

  img {
    width: 70%;

    @media (max-width: 780px) {
      width: 150%;
    }
  }

  @media (max-width: 780px) {
    flex-direction: column;
    margin-top: 2rem;
  }
}

.ordersEmptyBackGround {
  width: 100%;
  height: 100%;
  background-image: url("../img/orders.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.text-underline {
  text-decoration: underline;
}

body {
  font-family: "Helvetica", "Arial", sans;
}
// button.Add2D:hover {
//   color: #ffc107
// }

button.ProductAdd,
button.MerchantUpgrade {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  margin-bottom: 24px + 68px;
  background: #f2f2f2;
  border: none;

  span {
    display: block;
    text-align: center;
    color: white;
  }

  span:first-child {
    font-size: 68px;
    padding-top: 24%;
  }

  span:last-child {
    font-size: 18px;
    padding-top: 4%;
  }
}

button.ProductAdd {
  span:first-child {
    font-size: 102px;
    line-height: 102px;
    padding-top: 22%;
  }

  h2 {
    display: block;
    text-align: center;
    font-size: 30px;
    line-height: 50px;
    color: #5c5c5c;
    padding-top: 45%;
    transition: .2s;
  }

}

.PContainer {
  &:hover {
    h2 {
      color: #ffc107 !important
    }
  }
}

.model-h2 {
  display: block;
  text-align: center;
  font-size: 50px;
  line-height: 50px;
  color: $yellow;
}

button.MerchantUpgrade {
  background: $yellow;
  font-size: 72px;
}

button {
  box-shadow: none !important;
}

button.-is-invisible {
  border: $white;
  color: $white;

  &:hover,
  &:active,
  &:focus {
    box-shadow: none !important;
    color: $white !important;
    border: $white;
    background-color: $white !important;
  }
}

button {
  margin-right: 12px;
}

strong {
  display: inline-block;
}

// map
.map {
  margin-bottom: 12px;
}

// merchant image
.merchant-image {
  width: 100%;
  /*max-width: 900px;*/
  margin-bottom: 24px;
}

.website-url {
  display: inline-block;
  margin: 24px 0;
}

// opening times
.opening-hours-day {
  margin-bottom: 8px;

  select {
    display: inline-block;
    width: 58px;
    margin: 0 4px 8px 0;
  }

  span {
    padding-right: 4px;
  }

  span.times-separator {
    padding-right: 8px;
  }

  a {
    display: inline-block;
    margin-bottom: 9px;

    @media (min-width: 780px) {
      margin-bottom: 0;
    }
  }
}

// faq
.faq-wrap {
  h3 {
    color: $yellow;
    font-size: 21px;
    margin-top: 32px;
  }
}

// Active button
.btn-activate {
  background-color: #66ff66;
  border-color: #4cae4c;
}

.btn-activate:hover {
  color: #fff;
  background-color: #449d44;
  border-color: #398439;
}

.btn-activate:active,
.btn-activate.active,
.open>.dropdown-toggle.btn-activate {
  color: #fff;
  background-color: #449d44;
  border-color: #398439;
}

.btn-activate:active:hover,
.btn-activate.active:hover,
.open>.dropdown-toggle.btn-activate:hover,
.btn-activate:active:focus,
.btn-activate.active:focus,
.open>.dropdown-toggle.btn-activate:focus,
.btn-activate:active.focus,
.btn-activate.active.focus,
.open>.dropdown-toggle.btn-activate.focus {
  color: #fff;
  background-color: #398439;
  border-color: #255625;
}

.btn-activate:active,
.btn-activate.active,
.open>.dropdown-toggle.btn-activate {
  background-image: none;
}

.btn-activate.disabled:hover,
.btn-activate[disabled]:hover,
fieldset[disabled] .btn-activate:hover,
.btn-activate.disabled:focus,
.btn-activate[disabled]:focus,
fieldset[disabled] .btn-activate:focus,
.btn-activate.disabled.focus,
.btn-activate[disabled].focus,
fieldset[disabled] .btn-activate.focus {
  background-color: #66ff66;
  border-color: #4cae4c;
}

.btn-activate .badge {
  color: #66ff66;
  background-color: #fff;
}

.btn-bin {
  margin-right: 0 !important;
  padding: 0 !important;
  border: none;
  background: none;
  margin-bottom: 15px;
  width: 30px !important;
  height: 30px !important;
}

.btn-download {
  margin-right: 0 !important;
  padding: 0 !important;
  border: none;
  background: none;
  margin-bottom: 15px;
  width: 30px !important;
  height: 30px !important;
}

.btn-image-action {
  text-transform: uppercase;
  border: none;
  color: white;
  font-weight: 600;
  background-color: transparent;
}

.btn-edit {
  position: absolute;
  bottom: 8px;
  left: 15px;
}

.btn-extraction {
  position: absolute;
  bottom: 8px;
  right: 15px;
}

.btn-hover-white:hover  {
  color: white;
  opacity: 0.9;
  // background-color: mix(blue, black, 25%)
}
#img-download {
  width: 30px;
  height: 30px;
}

#img-bin {
  width: 30px;
  height: 30px;
}

/*
footer {
  margin-top: 100px;
  padding-top: 12px;
  border-top: 2px solid $yellow;
  text-align: center;
}
*/

footer {
  padding-top: 30px;
  padding-bottom: 1px;
}

.transbox {
  position: absolute;
  width: 100%;
  background-color: #000000;
  opacity: 0.6;
  height: 40px;

  span {
    color: #ffffff;
  }
}
#root {
  margin-bottom: -30px;
}

main {
  min-height: calc(100vh - 65px);
  // overflow: auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
}

.hsidebar {
  min-height: calc(100vh - 65px) !important;
  // overflow: scroll;
}

.hsidebarContent {
  min-height: calc(100vh - 65px) !important;
  position: fixed;
  // overflow: scroll;
}

.text-yellow {
  color: $yellow;
}

.emptyOrders {
  width: 100%;
  height: auto;
}

@media screen and (max-width: 761px) {
  main {
    height: 100%;
    min-height: 95vh;
    overflow: visible;
  }
}

.soldOut {
  background: #c09de1;
  border-bottom-left-radius: 12px;
  border-top-right-radius: 12px;
  color: #fff;
  right: 0;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

@media (min-width: 640px) {
  .container2 {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container2 {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container2 {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container2 {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container2 {
    max-width: 1536px;
  }
}

.container2 {
  width: 100%;
}

.vertical-centered {
  min-height: 85vh;
  padding: 0;
}

.login-container-form {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  border: 1px solid rgb(224, 224, 224);
  height: 100% !important;
  padding-bottom: 60px !important;

  h1 {
    margin-top: 60px !important;
    margin-bottom: 50px !important;
  }
}

.blue-hover {
  color: #343a40 !important;
}
.blue-hover:hover {
  color: rgb(59,130,246) !important;
}
.blue-hover2:hover {
  color: rgb(59,130,246) !important;
}
.tooltip2 {
  position: relative;
}

/* Tooltip text */
.tooltiptext {
  opacity: 0;
  position: absolute;
  bottom: 0px;
  left: -70px;
  z-index: 10;
  width: 70px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  transition: .3s ease all;
}

.tooltip2:focus .tooltiptext {
  opacity: 1;
}

.check-mark { 
  background-color: #28a745;
  border-radius: 100%;
  width: 25px;
  height: 25px;
}

.check-mark-gray {
  background-color: #8a8a8a;
  border-radius: 100%;
  width: 25px;
  height: 25px;
}

.custom-range::-webkit-slider-thumb {
  background: gray;
}

.custom-range::-moz-range-thumb {
  background: gray;
}

.custom-range::-ms-thumb {
  background: gray;
}

.variations-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

#cursor-circle {
  position: fixed;
  display: none;
  width: 20px;
  aspect-ratio: 1/1;
  border: 1px solid black;
  border-radius: 50%;
  background-color: transparent;
  pointer-events: none;
}

.cursor-none {
  cursor: none;
}

// /* Safari and Chrome */
// ::-webkit-scrollbar {
//   width: 10px; /* Make it less wide */
//   height: 10px;
// }
// ::-webkit-scrollbar-horizontal {
//     width:  10px; /* Set horizontal scrollbar width to  10px for desktop */
//   }
// /* Track */
// ::-webkit-scrollbar-track {
//   border-radius: 10px; /* Rounded borders */
// }

// /* Handle */
// ::-webkit-scrollbar-thumb {
//   background: #bebdbd; /* Lighter color */
//   border-radius: 10px; /* Rounded borders */
// }

// /* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   background: #a7a5a5;
// }

// /* For Firefox */
// * {
//   scrollbar-width: thin; /* Make it less wide */
//   scrollbar-color: #888 #f1f1f1; /* thumb and track color */
// }

// /* MEdge */
// * {
//   -ms-overflow-style: -ms-autohiding-scrollbar;
// }

/* Safari and Chrome */
::-webkit-scrollbar {
  width:  10px; /* Make it less wide */
  height:  10px; /* Make it less tall */
}

/* Track */
::-webkit-scrollbar-track {
  border-radius:  10px; /* Rounded borders */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bebdbd; /* Lighter color */
  border-radius:  10px; /* Rounded borders */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a7a5a5;
}

/* For Firefox */
* {
  scrollbar-width: thin; /* Make it less wide */
  scrollbar-height: thin; /* Make it less tall */
  scrollbar-color: #bebdbd #f1f1f1; /* thumb and track color */
}

/* For Edge */
* {
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

/* For Internet Explorer */
html {
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scrollbar-base-color: #bebdbd;
  scrollbar-3dlight-color: #f1f1f1;
  scrollbar-highlight-color: #f1f1f1;
  scrollbar-track-color: #f1f1f1;
  scrollbar-arrow-color: #bebdbd;
  scrollbar-shadow-color: #f1f1f1;
  scrollbar-darkshadow-color: #f1f1f1;
}

