$yellow: #ffb000;
$headerHeight: 48px;

@media (max-width: 779px) {
  main {
    padding-top: $headerHeight + 24px;
  }
}

.menu-wrap {
  .nav-tabs {
    border-bottom: 1px solid $yellow !important;
  }

  .nav-tabs>li>a.active,
  .nav-tabs>li>a:hover {
    color: #495057;
    background-color: #fff !important;
    border-color: $yellow $yellow #fff;
  }

  ul {
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    width: 100%;
  }

  ul>li {
    justify-content: center;
    display: flex;
  }

  @media (max-width: 780px - 1) {
    ul {
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: 0px;
    }

    &.menu-open {
      top: $headerHeight;
    }

    .nav-tabs>li {
      float: none !important;
    }

    .nav-tabs>li.active>a,
    .nav-tabs>li.active>a:hover,
    .nav-tabs>li.active>a:focus {
      border: none;
    }

    .container {
      padding-bottom: 16px;
    }
  }
}

nav {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.navbar {
  flex-wrap: nowrap !important;
}

#NavbarOptions, #ProductOptions {
  width: 100%;
  background-color: white;
  min-height: 100vh !important;
  position: absolute;
  z-index: 999;
}

.py-11 {
  min-height: 100vh;
  padding-top: 120px;
  padding-bottom: 120px;

  li {
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: bold;
  }

  li:last-of-type {
    margin-top: 100px;

    a {
      cursor: pointer;
      color: #939393 !important;
      margin: 0 8px;
    }
    
    a.active {
      cursor: pointer;
      color: #ffc107 !important;
      text-decoration: underline;
      margin: 0 8px;
    }
  }
}

.redLogout {
  // color: #bf605a !important;
  color: #FF2424 !important;
}

.navbarButton {
  border: none;
  background: transparent;
  color: white;
}