.categoryContainer {
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
}

.categoryTab {
  font-weight: 500;
  text-transform: capitalize;
  min-height: 5px;
  padding: 0px;
  margin: 5px;
  min-width: 10px;
  color: #5c5c5c;
  text-decoration: none;
  border: none;
  background: none;
}

.active {
  font-weight: 700;
  border-bottom: 2px solid #5c5c5c;
}

.notActive {
  font-weight: 500;
  color: #ff2424;
}

.notPublished {
  color: #bf605a !important;
}

.active.notPublished {
  border-bottom: 2px solid #bf605a;
}
