$lightgrey: #f2f2f2;
$darkgrey: #9b9b9b;
$blue: #54a3ff;
$grey: #9b9b9b;

.section-bg {
  background-color: $lightgrey;
  border-radius: 8px;
  padding-top: 25px;
  padding-left: 25px;
}

.section-btn {
  border-color: $lightgrey !important;
  background-color: $lightgrey !important;
  color: $blue !important;
  text-transform: uppercase;
  font-weight: bold !important;
}
