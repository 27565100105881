$small: 300px;
$medium: 900px;

.full_header_container {
  display: flex;
  flex-direction: column;
  border: 1px solid #dee2e6 !important;

  * {
    font-family: system-ui, -apple-system, "Segoe UI", "Roboto", "Helvetica", "Arial", "sans-serif", "Apple Color Emoji", "Segoe UI Emoji" !important;
  }
}

.header_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  padding-left: 0px;
  padding-right: 0px;
  height: 100px;

  @media screen and (max-width: $medium) {
    height: 60px;
  }
}

.header_language_picker {
  padding-left: 0;
  .language_picker_button_regular {
    position: relative;
    display: flex;
    justify-content: space-between;
    color: #9b9b9b;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    border-radius: 0.3rem;
    background-color: #fff;
    width: 8rem;
    margin-top: 0.25rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1) !important;
    box-sizing: border-box;
    border: none;

    @media screen and (max-width: $medium) {
      width: 6rem;
    }

    img {
      width: 20px !important;
      height: auto !important;
      margin-right: 6px;
      margin-left: 2px;
    }
  }
  .language_picker_button_transparent {
    position: relative;
    display: flex;
    justify-content: space-between;
    color: #9b9b9b;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    border-radius: 0.3rem;
    background-color: transparent;
    width: 8rem;
    margin-top: 0.25rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1) !important;
    box-sizing: border-box;
    border: none;

    @media screen and (max-width: $medium) {
      width: 6rem;
    }

    img {
      width: 20px !important;
      height: auto !important;
      margin-right: 4px;
      margin-left: 2px;
    }
  }
}

.header_logo {
  display: flex;
  justify-content: center;
  margin-top: 5px;

  a {
    img {
      width: 130px;
      height: 30px;

      @media screen and (max-width: $medium) {
        height: 18px;
        width: auto;
      }
    }
  }

  @media screen and (max-width: $medium) {
    margin-top: 0px;
  }
}

.header_links {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 0 !important;

  .create_account {
    padding: 1.0625em 1.875em;
    border-radius: 4px;
    font-family: "HelveticaNeue-Medium", "Helvetica Neue Medium", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    font-size: 1em;
    line-height: 1.133;
    -webkit-font-smoothing: antialiased;
    transition: .15s ease;
    transition-property: background-color, border-color, box-shadow, color;
    text-align: center;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, .15);
    background-color: #bd10e0 !important;
    border: 0 solid transparent;
    font-weight: 700;
    color: white;
    margin-left: 8px;
    margin-right: 0;

    &:hover {
      color: white;
    }
  }

  a {
    text-decoration: none !important;
    color: #9b9b9b;
    margin-left: 8px;
    margin-right: 8px;
    font-weight: 600;
    font-size: 16px;

    &:hover {
      color: #9b9b9b;
    }
  }

  @media screen and (max-width: $medium) {
    display: none;
  }
}

.items_center {
  display: flex;
  align-items: center;
}

ul.language_list {
  position: absolute;
  top: 3rem;
  padding-top: 0.25rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  padding-left: 0;
  width: 112px;
  list-style: none;
  border-radius: 0.3rem;
  overflow: hidden;
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
  z-index: 999;

  li {
    color: #9b9b9b;
    background: #fff;
    max-width: 112px;
    height: 40px;
    display: flex;
    width: 100%;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin-right: 0 !important;
    cursor: pointer;

    &:hover {
      background-color: rgb(254, 243, 199);
    }

    img {
      width: 20px !important;
      height: 20px !important;
    }

    svg {
      width: 20px !important;
      height: 20px !important;
    }

    div {
      font-size: 13px;
      font-weight: lighter;
      margin-left: 5px;
      margin-right: 5px;
    }

    .col_1 {
      width: 30px !important;
      padding-left: 5px;
    }

    .col_2 {
      width: 30px !important;
    }

    .col_3 {
      width: 50px !important;
    }
  }
}

.active {
  background-color: rgb(254, 243, 199) !important;
}

.header_links_mobile {
  display: none;
  width: 100%;

  button {
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
  }

  @media screen and (max-width: $medium) {
    display: flex;
    justify-content: flex-end;
  }
}

.drawerOpen {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #dee2e6 !important;
  overflow: hidden;
  height: 80vh;
  transition: .4s ease all;

  ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;

    li {
      list-style: none;
      margin: 22px;

      a {
        text-decoration: none;
        color: #6b7280;
        height: 22px;
        font-weight: 600;
        font-size: 13px;
      }
    }
  }
}

.drawerClose {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: .4s ease all;
  height: 0;

  ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;

    li {
      list-style: none;
      margin: 22px;

      a {
        text-decoration: none;
        color: #6b7280;
        height: 22px;
        font-weight: 600;
        font-size: 13px;
      }
    }
  }
}

.drawer_social {
  display: flex;

  a {
    width: 34px !important;
    height: 34px !important;
    margin: 8px;
  }
}

.hamburguer_box {
  width: 30px;
  height: 24px;
  display: inline-block;
  position: relative;
  margin-right: 5px;
}

.hamburguer_inner,
.hamburguer_inner::after,
.hamburguer_inner::before {
  width: 30px !important;
  height: 3px !important;
  background-color: #ffb000;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: ease;
}

.hamburguer_inner {
  display: block;
  top: 70%;
  margin-top: -4px;
}

.hamburguer_inner::before,
.hamburguer_inner::after {
  content: "";
  display: block;
}

.hamburguer_inner::before {
  top: -10px;
}

.hamburguer_inner::after {
  bottom: -10px;
}

.hamburguer_inner_open,
.hamburguer_inner_open::after,
.hamburguer_inner_open::before {
  width: 30px !important;
  height: 3px !important;
  background-color: #ffb000;
  border-radius: 4px;
  position: absolute;
}

.hamburguer_inner_open {
  display: block;
  bottom: 0;
  margin-top: -4px;
  transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: .32s;
  transition-timing-function: cubic-bezier(.215, .61, .355, 1);
}

.hamburguer_inner_open::before,
.hamburguer_inner_open::after {
  content: "";
  display: block;
}

.hamburguer_inner_open::before {
  top: 0px;
  transform: rotate(-90deg);
  transition: top .12s cubic-bezier(.33333, 0, .66667, .33333) .18s, transform .15s cubic-bezier(.215, .61, .355, 1) .42s;
}

.hamburguer_inner_open::after {
  bottom: -10px;
  top: 0;
  opacity: 0;
  transition: top .3s cubic-bezier(.33333, 0, .66667, .33333), opacity .1s linear .27s;
}