

.container,
.StripeElement {
  width: 100% !important;
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}
.relative {
  position: relative;
}
.z-30 {
  z-index: 30;
}
.m-0 {
  margin: 0;
}
.m-16 {
  margin: 4rem;
}
.my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}
.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}
.mb-5 {
  margin-bottom: 1.25rem;
}
.mr-1 {
  margin-right: 0.25rem;
}
.mt-3 {
  margin-top: 0.75rem;
}
.mt-4 {
  margin-top: 1rem;
}
.mb-3 {
  margin-bottom: 0.75rem;
}
.mb-1 {
  margin-bottom: 0.25rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mb-24 {
  margin-bottom: 6rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.flex {
  display: flex;
}
.hidden {
  display: none;
}
.h-10 {
  height: 2.5rem;
}
.w-full {
  width: 100%;
}
.w-56 {
  width: 14rem;
}
.w-10 {
  width: 2.5rem;
}
.max-w-6xl {
  max-width: 72rem;
}
.cursor-pointer {
  cursor: pointer;
}
.flex-col {
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.items-start {
  align-items: flex-start;
}
.items-end {
  align-items: flex-end;
}
.items-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.gap-2 {
  gap: 0.5rem;
}
.gap-x-0 {
  -moz-column-gap: 0;
  column-gap: 0;
}
.rounded-full {
  border-radius: 9999px;
}
.rounded-xl {
  border-radius: 0.75rem;
}
.border-0 {
  border-width: 0;
}
.border-2 {
  border-width: 2px;
}
.border-blue-500 {
  --tw-border-opacity: 1;
  border-color: rgb(59 130 246 / var(--tw-border-opacity));
}
.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}
.bg-green-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(134 239 172 / var(--tw-bg-opacity));
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}
.p-4 {
  padding: 1rem;
}
.p-3 {
  padding: 0.75rem;
}
.p-1 {
  padding: 0.25rem;
}
.p-8 {
  padding: 2rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-14 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}
.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.text-center {
  text-align: center;
}
.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}
.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}
.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}
.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.text-5xl {
  font-size: 3rem;
  line-height: 1;
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.font-semibold {
  font-weight: 600;
}
.font-bold {
  font-weight: 700;
}
.leading-none {
  line-height: 1;
}
.leading-10 {
  line-height: 2.5rem;
}
.leading-6 {
  line-height: 1.5rem;
}
.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}
.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity));
}
.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}
.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}
.shadow {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),
    0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}
.transition-transform {
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.duration-150,
.transition-transform {
  transition-duration: 0.15s;
}
.duration-500 {
  transition-duration: 0.5s;
}
.ease-linear {
  transition-timing-function: linear;
}
.hover\:bg-blue-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity)) !important;
}
.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.focus\:ring-0:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}
.active\:bg-blue-600:active {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}
@media (min-width: 640px) {
  .sm\:mb-0 {
    margin-bottom: 0;
  }
}
@media (min-width: 768px) {
  .md\:mt-0 {
    margin-top: 0;
  }
  .md\:w-auto {
    width: auto;
  }
  .md\:w-80 {
    width: 20rem;
  }
  .md\:gap-x-20 {
    -moz-column-gap: 5rem;
    column-gap: 5rem;
  }
  .md\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .md\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .md\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}
@media (min-width: 1024px) {
  .lg\:mt-0 {
    margin-top: 0;
  }
  .lg\:w-1\/2 {
    width: 50%;
  }
}
/* Estilos para el contenedor principal */
.bg-yellow-200 {
  background-color: #fde68a;
}

.text-black {
  color: #000;
}

.border {
  border-width: 1px;
  border-style: solid;
}

.border-2 {
  border-width: 2px;
  border-style: solid;
}

.border-gray-200 {
  border-color: #e5e7eb;
}

.bg-gray-50 {
  background-color: #f9fafb;
}

.bg-opacity-80 {
  opacity: 0.8;
}

.hover\:bg-gray-100:hover {
  background-color: #f3f4f6;
}

.bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
}

.rounded-b-xl {
  border-bottom-right-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}

.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity));
}

.a-blue-500:hover {
  border-color: #3b82f6 !important;
}
