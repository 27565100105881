body {
  font-family: 'Helvetica', 'Arial', sans !important;
}

/*
.header-wrap {
  height: 80px ;
  border-bottom: 1px solid #F2F2F2 ;
}*/

header {
  padding-top: 10px !important;
}

.header-home {
  display: inline-block !important;
  width: 100px !important;
  height: 21px !important;
  background-image: url('/yuge-logo-orange.svg');
  text-indent: -9999px !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
}

.header-wrap h1 {
  margin: 0 !important;
  margin-top: 20px !important;
}

.header-home {
  color: #ffb000 !important;
  font-size: 24px !important;
  font-size: 19px !important;
  font-weight: 100 !important;
  padding: 17px 10px !important;
  display: inline-block !important;
}

.header-home:hover {
  color: #dee0e2 !important;
  color: #ffb000 !important;
  text-decoration: none !important;
}

ul.i18n {
  margin-top: 22px;
}

ul.i18n li {
  display: inline-block;
  margin-left: 16px;
}

h1 {
  color: #ffb000 !important;
  margin-top: 96px !important;
  margin-bottom: 48px !important;
  font-weight: 900 !important;
}

h1 span {
  font-weight: 100 !important;
}

.eyeIcon {
  position: absolute;
  top: 8px;
  right: 22px;
  cursor: pointer;
}

@keyframes opacityCycle {
  0% {
    opacity: 0.2;
  }

  50% {
    opacity: 0.7;
  }

  100% {
    opacity: 0.2;
  }
}

/* OPTION 1 for disabled elements. All grey */
.disabledElement {
  box-sizing: border-box;
  opacity: 0.7;
  cursor: wait !important;
  border: none !important;
  text-decoration: none;
  color: #999 !important;
  background-color: #e0e0e0 !important;
  border-radius: 0.25rem;
}

.slide-fade {
  transition: height 0.3s ease-in-out;
  overflow: hidden;
}

/* OPTION 2 for disabled elements. Opacity animation */
/* .disabledElement {
  box-sizing: border-box;
  opacity: 0.5;
  cursor: wait !important;
  border: none !important;
  text-decoration: none;
  animation: opacityCycle 2s infinite; 
} */

/* .disabledElement:hover {
  color: #999 !important; 
  background-color: #e0e0e0 !important; 
} */
