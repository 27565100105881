.modal {
    * {
        font-family: system-ui,-apple-system,"Segoe UI","Roboto","Helvetica","Arial","sans-serif","Apple Color Emoji","Segoe UI Emoji"!important;
    }
    min-width: 100% !important;
    justify-content: space-around;
    padding: 0 !important;

    .modal_dialog {
        max-width: 680px;
        border: none;
        
        .modal_content {
            border-radius: 1rem;
            border: none;
        }
    }
}

form {
    padding: 10px;
}

.submit {
    width: 100%;
    background-color: #facc15 !important;
    color: #975a16;
    font-weight: 600;
    padding: 1rem;
    box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important;
    border-radius: 0.25rem;
    border-color: transparent!important;
    font-size: 0.9rem;
    transition: 0.2s ease all;

    &:active {
        color: white;
    }
}

.form_group {
    display: flex;
    flex-direction: column;
    width: 100%;

    label {
        width: 100%;
        font-size: 14px;
        font-weight: 300;
        color: #212529;
        margin-bottom: 5px;
    }

    input {
        margin-bottom: 0.25rem;
        border: 1px solid #dee2e6!important;
        width: 100%;
    }

    .error_message {
        color: rgba(239,68,68, .7);
        font-size: 1rem;
        line-height: 1;
        margin-top: 0.25rem;
    }
}

.form_group + .form_group {
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.error_message {
    color: rgba(239,68,68, .7);
    font-size: 1rem;
    line-height: 3;
    margin-top: 8px !important;
}